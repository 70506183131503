import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProduct from "@/vue/domain/product/df-product";
import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Carousel, Slide } from "vue-carousel-variable-width";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
const DfCardProductComponent = () => Utils.externalComponent2("df-card-product");

@Component({
  components: {
    DfCardProduct: DfCardProductComponent,
    Carousel,
    Slide,
  },
})
export default class DfProductDetailsRelatedProductsComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: String, default: null })
  promotionAlias: string;
  @Prop({ type: Array, default: [] })
  productsUniqueId: Array<string>;
  @Prop({ type: String, default: "smt-digitalflyer-ui" })
  origin: string;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  get recipeProducts(): Array<DfProduct> {
    return this.cachedProducts.filter((product: DfProduct) => this.productsUniqueId.indexOf(product.uniqueId) > -1);
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get isAllProductSelected(): boolean {
    let result = true;
    this.recipeProducts.forEach((recipeProduct: DfProduct) => {
      const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === recipeProduct.description);
      if (index === -1) {
        result = false;
      }
    });
    return result;
  }

  private selectAllProducts() {
    if (this.isAllProductSelected) {
      this.recipeProducts.forEach((product: DfProduct) => this.deselectProduct(product));
    } else {
      this.recipeProducts.forEach((product: DfProduct) => this.selectProduct(product));
    }
  }

  private selectProduct(recipeProduct: DfProduct) {
    const title: string = Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_TITLE, "STRING");
    const description: string = Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_DESCRIPTION, "STRING");
    const longDescription: string = Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_LONG_DESCRIPTION, "TEXT");
    const textCheck: string = title || description || longDescription || recipeProduct.description;

    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === textCheck);
    if (index === -1) {
      this.shoppingList = [new DfShoppingProduct(textCheck, false), ...this.shoppingList];
      Utils.setAnaliticsTraker({
        event: "add_product_to_shopping_list",
        origin: this.origin,
        productAlias: recipeProduct.alias,
        productBrand: Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_BRAND, "STRING"),
        productCategory: Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_CATEGORY, "STRING"),
        productCode: recipeProduct.code,
        productDescription: recipeProduct.description,
        productDiscountType: Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_DISCOUNT, "STRING"),
        productFeatured: Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_FEATURED, "STRING"),
        productInitialPrice: Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_INITIAL_PRICE, "STRING"),
        productPrice: Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_FINAL_PRICE, "STRING"),
        productTitle: Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_TITLE, "STRING"),
        promotionAlias: "",
        promotionBeginDate: "",
        promotionCode: "",
        promotionDescription: "",
        promotionEndDate: "",
        storeAlias: this.currentStore.alias,
        storeCode: this.currentStore.code,
        storeName: this.currentStore.name,
      });

    }
  }

  private deselectProduct(recipeProduct: DfProduct) {
    const title: string = Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_TITLE, "STRING");
    const description: string = Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_DESCRIPTION, "STRING");
    const longDescription: string = Utils.getPropertyValue(recipeProduct, Utils.PROPERTY_PRODUCT_LONG_DESCRIPTION, "TEXT");
    const textCheck: string = title || description || longDescription || recipeProduct.description;

    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === textCheck);
    if (index !== -1) {
      this.shoppingList = this.shoppingList.slice(0, index).concat(this.shoppingList.slice(index + 1, this.shoppingList.length));
    }
  }

  @Watch("productsUniqueId", { immediate: true })
  private productsUniqueIdChange() {
    this.loadProductsList();
  }

  private loadProductsList() {
    if (this.productsUniqueId.length > 0 && this.productsUniqueId.length !== this.recipeProducts.length) {
      this.$root.$emit("loadCachedProducts", this.productsUniqueId);
    }
  }
}
